export const customTypographyElements = {
  kbd: {
    display: "inline-flex",
    width: "max-content",
    fontFamily: "monospace",
    fontSize: "0.75rem", // Equivalent to xs
    fontWeight: 700, // Equivalent to lg
    // borderColor: 'var(--mui-palette-foreground-level4)',
    borderStyle: "solid",
    borderWidth: "1px 1px 3px",
    // backgroundColor: 'var(--mui-palette-foreground-level5)',
    borderRadius: "0.35rem",
    padding: "0 4px",
  },
}
