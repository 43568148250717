import { gridClasses } from "@mui/x-data-grid-pro";
import { Theme } from "@mui/material/styles";
import { AlertProps } from "@mui/material";

const commonComponents = {
  MuiCssBaseline: {
    styleOverrides: `
        a {
          color: var(--mui-palette-text-primary);
          text-decoration: none;
          &:hover {
            color: var(--mui-palette-primary-main);
            text-decoration: underline;
          }
        }
      `,
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        boxShadow: 'none'
      },
    }
  },
  MuiAlert: {
    styleOverrides: {
      filledInfo: (props: { theme: Theme, ownerState: AlertProps }) => ({
        color: props.theme.palette.text.secondary,
        backgroundColor: props.theme.palette.background.light,
      })
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      root: {
        // backgroundColor: 'var(--mui-palette-background-light)',
      }
    }
  },
  MuiFilledInput: {
    styleOverrides: {
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px var(--mui-palette-background-light) inset',
          WebkitTextFillColor: 'var(--mui-palette-text-primary)',
          caretColor: 'var(--mui-palette-text-primary)',
          color: 'var(--mui-palette-text-primary)'
        }
      },
      root: {
        backgroundColor: 'var(--mui-palette-background-light)',
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid var(--mui-palette-text-primary)',
        },
      }
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      listbox: {
        backgroundColor: 'var(--mui-palette-background-light)',
      },
      option: {
        backgroundColor: 'none',
        '&:hover': {
          backgroundColor: 'var(--mui-palette-background-dark) !important',
        }
      },
      inputRoot: {
        backgroundColor: 'var(--mui-palette-background-light)',
        paddingTop: 10,
        paddingBottom: 10,
      }
    }
  },
  MuiChip: {
    styleOverrides: {
      root: {
        margin: '2px',
        backgroundColor: 'var(--mui-palette-background-dark)',
        color: 'var(--mui-palette-text-light)',
      }
    }
  },
  MuiTypography: {
    styleOverrides: {
      h3: {
        fontSize: "1.4rem",
      },
      h4: {
        fontSize: "1.3rem",
      },
      h5: {
        fontSize: "1.1rem",
      },
      h6: {
        fontSize: "1rem",
      },
    },
  },
  // @TODO: how to hide the search input column management header properly?
  MuiPopper: {
    styleOverrides: {
      root: {
        zIndex: 9999,
        '& .MuiDataGrid-columnsManagement': {
          marginTop: 13,
        },
        '& .MuiDataGrid-columnsManagementHeader': {
          display: "none"
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minHeight: 48,
        color: 'var(--mui-palette-text-primary)',
        '&.Mui-selected': {
          color: 'var(--mui-palette-text-primary)',
        }
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: 48,
        '.MuiTabs-scroller .MuiTabs-indicator': {
          backgroundColor: 'var(--mui-palette-text-primary)',
        }
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        color: 'unset',
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: 'unset',
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        // backgroundColor: 'var(--mui-palette-background-level2)',
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: 'unset'
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        minHeight: 'unset',
        maxHeight: '40px',
        // backgroundColor: 'var(--mui-palette-background-level2)',
        '&:hover': {
          // backgroundColor: 'var(--mui-palette-background-level3)',
        }
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        backgroundColor: 'var(--mui-palette-background-default)',
        paddingBottom: 25,
        overflow: 'visible'
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        // borderBottom: '1px solid var(--mui-palette-divider)',
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {},
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        paddingLeft: 25,
        paddingRight: 25,
        paddingBottom: 0,
      }
    }
  },
  MuiModal: {
    styleOverrides: {
      root: {
        // zIndex: 11000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
  MuiCollapse: {
    styleOverrides: {
      root: {
        color: 'unset',
        backgroundColor: 'unset !important',
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        // color: 'unset',
        // backgroundColor: 'unset',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        borderRadius: 0,
        backgroundImage: 'none',
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        paddingRight: 0,
        borderBottom: 0,
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
        '--DataGrid-containerBackground': 'transparent',
        border: 0,
        borderRadius: 0,
        '& .MuiDataGrid-footerContainer': {
          minHeight: 35,
          marginBottom: 10,
          '&.MuiDataGrid-withBorderColor': {
            borderColor: 'transparent',
          },
        },
        '& .MuiDataGrid-row--borderBottom': {
          borderBottomColor: 'var(--mui-palette-divider)',
        },
        '& .MuiDataGrid-scrollbarFiller': {
          display: 'none'
        },
        '& .MuiDataGrid-main:focus-visible': {
          outline: 'none',
        },
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}`]: {
          height: '49px !important',
          backgroundColor: 'var(--mui-palette-background-default)',
          borderBottomColor: 'var(--mui-palette-divider) !important',
        },
        '& .MuiDataGrid-row': {
          border: 0,
          '&:hover': {
            backgroundColor: 'transparent'
          },
        },
        '& .MuiDataGrid-cell:focus': {
          outline: ' none',
        },
        '& .MuiDataGrid-filler > div': {
          borderTop: 'none',
        },
        '& .MuiDataGrid-detailPanel': {
          width: 'auto',
          paddingRight: '5px',
          paddingBottom: '5px',
          backgroundColor: 'transparent',
        },
        '& .MuiDataGrid-cell': {
          border: 'none',
          outline: 'none !important',
        },
        '& .MuiDataGrid-cell--editing': {
          background: 'none !important',
          boxShadow: 'none !important',
        },
      },
    },
  },
  FlexBox: {
    styleOverrides: {
      root: {
        minWidth: 0,
        display: 'flex',
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: 60,
      },
    },
  },
  MuiSlider: {
    styleOverrides: {
      root: {
        color: "var(--mui-palette-icon-light)",
      },
      Paper: {
        backgroundImage: "none",
      },
      thumb: {
        width: 8,
        height: 8,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      arrow: {
        '&:before': {
          backgroundColor: 'var(--mui-palette-background-light)',
        }
      },
      tooltip: {
        backgroundColor: 'var(--mui-palette-background-light)',
        boxShadow: 'var(--mui-shadows-3)',
        color: 'var(--mui-palette-text-primary)',
        fontSize: ".8em",
      },
    },
  },
};

export default commonComponents;
