import { extendTheme } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
import { deepmerge } from "@mui/utils";
import chroma from "chroma-js";
import { ITheme } from "@/interfaces/ITheme";
import darkComponents from "@/theme/dark/components.ts";
import lightComponents from "@/theme/light/components.ts";
import commonComponents from "@/theme/common/components.ts";
import { customTypographyElements } from "@/theme/common/customTypographyElements.ts";

// export const THEME_DARK_BACKGROUND = "#13272b";
export const THEME_DARK_BACKGROUND = "#022127";
export const THEME_LIGHT_BACKGROUND = "#e8eaf6";

export const LIGHT_GREEN = "#1DB954";
export const NAVY_GREEN = "#6081d1";

export const THEME_LIGHT = "light";
export const THEME_DARK = "dark";
export const THEME_CUSTOM = "custom";

const generateContrastColors = (baseColor: string, mode: PaletteMode | 'custom') => {
  const color = chroma(baseColor);

  if (mode === THEME_DARK || (mode === THEME_CUSTOM)) {
    return {
      primary: color.brighten(4).hex(),
      secondary: color.brighten(2.5).hex(),
      tertiary: color.brighten(1).hex(),
      quaternary: color.brighten(0.6).hex(),
      disabled: color.brighten(0.4).hex()
    };
  } else {
    return {
      primary: color.darken(5).hex(),
      secondary: color.darken(2.3).hex(),
      tertiary: color.darken(1).hex(),
      quaternary: color.darken(0.6).hex(),
      disabled: color.darken(0.4).hex()
    };
  }
};

const generatePaletteVariations = (baseColor: string) => {
  const color = chroma(baseColor);
  return {
    dark: color.darken(0.3).hex(),
    light: color.brighten(0.3).hex(),
    default: color.hex()
  };
};

export function appTheme(mode: PaletteMode | 'custom', customColor?: string): ITheme {
  // Determine base color based on mode and custom color
  const baseColor =
    mode === THEME_CUSTOM && customColor
      ? customColor
      : (mode === THEME_DARK
        ? THEME_DARK_BACKGROUND
        : THEME_LIGHT_BACKGROUND);

  // Generate palette variations based on the base color
  const variations = generatePaletteVariations(baseColor);

  // Generate contrast colors
  const contrastColors = generateContrastColors(baseColor, mode);

  // Determine the effective mode
  const effectiveMode: PaletteMode = mode === THEME_CUSTOM ? 'light' : mode;

  // Primary color selection based on theme
  const primaryColor = mode === THEME_DARK ? LIGHT_GREEN : NAVY_GREEN;
  const primaryVariations = generatePaletteVariations(primaryColor);

  // Merge components
  const mergedComponents = deepmerge(
    { components: commonComponents },
    mode === THEME_DARK
      ? { components: darkComponents }
      : { components: lightComponents }
  )

  // Create theme using extendTheme
  return extendTheme({
    colorSchemes: {
      light: {
        palette: {
          mode: 'light',
          common: {
            black: primaryVariations.dark,
            white: primaryVariations.light,
            background: variations.default,
            onBackground: variations.light,
            onBackgroundChannel: variations.default,
          },
          primary: {
            main: primaryVariations.default,
            dark: primaryVariations.dark,
            light: primaryVariations.light,
            contrastText: '#ffffff'
          },
          background: {
            default: baseColor,
            paper: variations.default,
            dark: variations.dark,
            light: variations.light
          },
          text: {
            primary: contrastColors.primary,
            secondary: contrastColors.secondary,
            disabled: contrastColors.disabled,
          },
          action: {
            disabled: contrastColors.disabled,
            active: contrastColors.primary,
            hover: chroma(contrastColors.primary).alpha(0.12).css(),
            selected: chroma(contrastColors.primary).alpha(0.16).css(),
            focus: chroma(contrastColors.primary).alpha(0.2).css(),
          },
          /*waveform: {
            played: '#2c494f', // contrastColors.secondary,
            unplayed: '#57747b' // contrastColors.tertiary,
          }*/
        }
      },
      dark: {
        palette: {
          mode: 'dark',
          common: {
            black: primaryVariations.dark,
            white: primaryVariations.light,
            background: variations.default,
            onBackground: variations.light,
            onBackgroundChannel: variations.default,
          },
          primary: {
            main: primaryVariations.default, // Use Spotify green for dark theme
            dark: primaryVariations.dark,
            light: primaryVariations.light,
            contrastText: '#000000'
          },
          background: {
            default: baseColor,
            paper: variations.default,
            dark: variations.dark,
            light: variations.light
          },
          text: {
            primary: contrastColors.primary,
            secondary: contrastColors.secondary,
            disabled: contrastColors.disabled,
          },
          action: {
            disabled: contrastColors.disabled,
            active: contrastColors.primary,
            hover: chroma(contrastColors.primary).alpha(0.12).css(),
            selected: chroma(contrastColors.primary).alpha(0.16).css(),
            focus: chroma(contrastColors.primary).alpha(0.2).css(),
          },
          waveform: {
            played: contrastColors.secondary,
            unplayed: contrastColors.quaternary,
          }
        }
      }
    },
    defaultColorScheme: effectiveMode,
    typography: {
      fontFamily: "Didact Gothic, sans-serif",
      ...customTypographyElements,
    },
    components: mergedComponents.components
  });
}
